import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/auth'
import user from '@/store/user'
import shop from '@/store/shop'
import line from '@/store/line'
import shopLineOA from '@/store/shop-lineoa'

Vue.use(Vuex)

const modules = {
  Auth: auth,
  User: user,
  Shop: shop,
  Line: line,
  ShopLineOA: shopLineOA,
}

export default new Vuex.Store({
  modules,
})