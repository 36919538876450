import Vue from 'vue'
import Router from 'vue-router'

/* ROUTER FOR WEB-PORTAL */

/* CONTAINER */
const TheContainer = () => import('@/containers/TheContainer')
const TheMenuContainer = () => import('@/containers/TheMenuContainer')

const Home = () => import('@/views/Home')

/* AUTHENTICATION */
const Login = () => import('@/views/login/Login')
const Logout = () => import('@/views/users/Logout')
const ForgotPassword = () => import('@/views/login/ForgotPassword')
const PageNotFound = () => import('@/views/404')
const ErrorPage = () => import('@/views/401')

/* SHOP */
const Shop = () => import('@/views/shop/Shop')

/* MEMBER */
const Member = () => import('@/views/member/shop/Member')
const CreateMember = () => import('@/views/member/shop/CreateMember')
const MemberDetail = () => import('@/views/member/shop/MemberDetail')

/* USER */
const User = () => import('@/views/users/User')

/* CRM-MENU : pending */
const CRMMenu = () => import('@/views/reward/CRMMenu')

/* REFERRAL */
const Referral = () => import('@/views/reward/referral/Index')

/* STORE-CREDIT */
const StoreCredit = () => import('@/views/reward/credit/Index')

/* PREPAID-ITEMS */
const PrepaidItems = () => import('@/views/reward/deal/Index')

/* CRM - TAB SETTINGS */
const CRMSettingMenu = () => import('@/views/reward/settings/Menu')

/* TRANSACTION */
const HistoryTransaction = () => import('@/views/reward/point/History')

/* ลงทะเบียนร้านค้า */
const RewardRegister = () => import('@/views/reward/Register')

/* คะแนนสะสม */
const LoyaltyPointMenu = () => import('@/views/reward/point/Menu')
const LoyaltyStamp = () => import('@/views/reward/stamp/shop/Index')
const LoyaltyStampDetail = () => import('@/views/reward/stamp/shop/Detail')
const LoyalStampCreate = () => import('@/views/reward/stamp/shop/CreateCoupon')

/* บัตรสะสมแต้ม */
const RewardCardMenu = () => import('@/views/reward/card/shop/Menu')
const RewardCardPreview = () => import('@/views/reward/card/shop/CardPreview')
const RewardCardProduct = () => import('@/views/reward/card/shop/Product')

/* บัตรกำนัล */
const Voucher = () => import('@/views/reward/voucher/shop/Menu')
const VoucherCreate = () => import('@/views/reward/voucher/shop/CreateVoucher')
const VoucherDetail = () => import('@/views/reward/voucher/shop/Detail')
const SendVouchers = () => import('@/views/reward/voucher/shop/SendVouchers')
const PreVoucherCreate = () =>
  import('@/views/reward/voucher/shop/CreatePreVoucher')
const PreVoucherDetail = () =>
  import('@/views/reward/voucher/shop/PreVoucherDetail')

/* คู่มือใช้งานระบบ */
const Manual = () => import('@/views/manual/Menu')
const LineOAManual = () => import('@/views/manual/LineOA')
const LineRegisterManual = () => import('@/views/manual/LineRegister')
const LoyaltyPointManual = () => import('@/views/manual/LoyaltyPoint')
const CreateCouponManual = () => import('@/views/manual/CreateCoupon')
const PointManual = () => import('@/views/manual/Point')
const LineNotifyManual = () => import('@/views/manual/LineNotify')
const RewardCardManual = () => import('@/views/manual/RewardCard')
const EVoucherManual = () => import('@/views/manual/EVoucher')
const PreVoucherManual = () => import('@/views/manual/PreVoucher')
const SmartMenuManual = () => import('@/views/manual/SmartMenu')

/* ------------------------------------------------------------------------------------ */

/* ROUTER FOR LINE-OA */

/* MEMBER */
const LineRegister = () => import('@/views/member/member/LineRegister')
const MemberBar = () => import('@/views/member/member/MemberBar')

/* LOYALTY-POINT AND STAMP (COUPON) */
const CouponMemberList = () => import('@/views/reward/stamp/member/CouponList')
const LoyalStampCoupon = () => import('@/views/reward/stamp/member/Coupon')
const MyCoupon = () => import('@/views/reward/stamp/member/MyCoupon')
const LoyalStampRedeem = () => import('@/views/reward/stamp/member/Redeem')
const LoyalStampRedemption = () =>
  import('@/views/reward/stamp/member/Redemption')

/* REWARD-CARD */
const RewardCardRedemption = () =>
  import('@/views/reward/card/member/Redemption')

/* E-Voucher */
const VoucherRedemption = () =>
  import('@/views/reward/voucher/member/Redemption')

/* Order */
/* const OrderMenu = () => import('@/views/order/Lists')
const OrderDetail = () => import('@/views/order/Detail')
const OrderByCategory = () => import('@/views/order/ByCategoryLists')
const MyCart = () => import('@/views/order/MyCart')
const PlaceOrder = () => import('@/views/order/PlaceOrder')
const Address = () => import('@/views/order/Address') */

/* Menu */
const SmartMenu = () => import('@/views/menu/Lists')
const ShopDetail = () => import('@/views/menu/ShopDetail')
const MenuByCategory = () => import('@/views/menu/ByCategoryLists')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: configRoutes(),
})

function configRoutes() {
  return [
    {
      path: '*',
      component: PageNotFound,
    },
    {
      path: '/error',
      component: ErrorPage,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/',
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'Home',
          component: Home,
        },
        {
          path: '/shop',
          name: 'Shop',
          component: Shop,
        },
        {
          path: '/transaction/history',
          name: 'HistoryTransaction',
          component: HistoryTransaction,
        },
        {
          path: '/user',
          name: 'User',
          component: User,
        },
        {
          path: '/user/logout',
          name: 'Logout',
          component: Logout,
        },
        {
          path: '/reward/register',
          name: 'RewardRegister',
          component: RewardRegister,
        },
        {
          path: 'loyalty-point',
          name: 'loyalty-point',
          component: LoyaltyPointMenu,
        },
        {
          path: '/referral',
          name: 'referral',
          component: Referral,
        },
        {
          path: '/store-credit',
          name: 'store-credit',
          component: StoreCredit,
        },
        {
          path: '/prepaid-items',
          name: 'prepaid-items',
          component: PrepaidItems,
        },
        {
          path: 'crm',
          name: 'crm',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'menu',
              name: 'CRMMenu',
              component: CRMMenu,
            },
            {
              path: 'settings',
              name: 'CRMSettingMenu',
              component: CRMSettingMenu,
            },
          ],
        },
        {
          path: 'loyalty-stamp',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'LoyaltyStamp',
              component: LoyaltyStamp,
            },
            {
              path: 'coupon/:objectId',
              name: 'LoyaltyStampDetail',
              component: LoyaltyStampDetail,
            },
            {
              path: 'create',
              name: 'LoyaltyStampCreate',
              component: LoyalStampCreate,
            },
          ],
        },
        {
          path: 'rewardcard',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'RewardCardMenu',
              component: RewardCardMenu,
            },
            {
              path: 'preview',
              name: 'RewardCardPreview',
              component: RewardCardPreview,
            },
            {
              path: 'settings/products/:rewardcardObjectId',
              name: 'RewardCardProduct',
              component: RewardCardProduct,
            },
          ],
        },
        {
          path: 'voucher',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Voucher',
              component: Voucher,
            },
            {
              path: 'create',
              name: 'VoucherCreate',
              component: VoucherCreate,
            },
            {
              path: ':objectId',
              name: 'VoucherDetail',
              component: VoucherDetail,
            },
            {
              path: 'send',
              name: 'SendVouchers',
              component: SendVouchers,
            },
            {
              path: 'prevoucher/create',
              name: 'PreVoucherCreate',
              component: PreVoucherCreate,
            },
            {
              path: ':objectId/prevoucher',
              name: 'PreVoucherDetail',
              component: PreVoucherDetail,
            },
          ],
        },
        {
          path: 'manual',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Manual',
              component: Manual,
            },
            {
              path: 'lineoa',
              name: 'LineOAManual',
              component: LineOAManual,
            },
            {
              path: 'line-register',
              name: 'LineRegisterManual',
              component: LineRegisterManual,
            },
            {
              path: 'loyalty-point',
              name: 'LoyaltyPointManual',
              component: LoyaltyPointManual,
            },
            {
              path: 'create-coupon',
              name: 'CreateCouponManual',
              component: CreateCouponManual,
            },
            {
              path: 'point',
              name: 'PointManual',
              component: PointManual,
            },
            {
              path: 'line-notify',
              name: 'LineNotifyManual',
              component: LineNotifyManual,
            },
            {
              path: 'reward-card',
              name: 'RewardCardManual',
              component: RewardCardManual,
            },
            {
              path: 'evoucher',
              name: 'EVoucherManual',
              component: EVoucherManual,
            },
            {
              path: 'pvoucher',
              name: 'PreVoucherManual',
              component: PreVoucherManual,
            },
            {
              path: 'menu',
              name: 'SmartMenuManual',
              component: SmartMenuManual,
            },
          ],
        },
        {
          path: 'member',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Member',
              component: Member,
            },
            {
              path: 'create',
              name: 'CreateMember',
              component: CreateMember,
            },
            {
              path: ':objectId/getdetail',
              name: 'MemberDetail',
              component: MemberDetail,
            },
          ],
        },
      ],
    },
    {
      path: '/member/register',
      name: 'LineRegister',
      component: LineRegister,
    },
    {
      path: '/member/card',
      name: 'MemberBar',
      component: MemberBar,
    },
    {
      path: '/member/getcoupon',
      name: 'CouponMemberList',
      component: CouponMemberList,
    },
    {
      path: '/coupon',
      name: 'LoyaltyStampCoupon',
      component: LoyalStampCoupon,
    },
    {
      path: '/mycoupon',
      name: 'MyCoupon',
      component: MyCoupon,
    },
    {
      path: '/redeem/:uid/:couponObjectId',
      name: 'LoyaltyStampRedeem',
      component: LoyalStampRedeem,
    },
    {
      path: '/redemption/:objectId',
      name: 'LoyaltyStampRedemption',
      component: LoyalStampRedemption,
    },
    {
      path: '/rewardcard/redemption/:objectId',
      name: 'RewardCardRedemption',
      component: RewardCardRedemption,
    },
    {
      path: '/voucher/redemption/:objectId',
      name: 'VoucherRedemption',
      component: VoucherRedemption,
    },
    /* {
      path: '/order-menu',
      component: TheMenuContainer,
      children: [
        {
          path: '',
          name: 'OrderMenu',
          component: OrderMenu,
        },
        {
          path: ':objectId/detail',
          name: 'OrderDetail',
          component: OrderDetail,
        },
        {
          path: ':categoryObjectId/category',
          name: 'OrderByCategory',
          component: OrderByCategory,
        },
        {
          path: 'mycart',
          name: 'MyCart',
          component: MyCart,
        },
        {
          path: 'order',
          name: 'PlaceOrder',
          component: PlaceOrder,
        },
        {
          path: 'address',
          name: 'Address',
          component: Address,
        },
      ],
    }, */
    {
      path: '/smart-menu',
      component: TheMenuContainer,
      children: [
        {
          path: '',
          name: 'SmartMenu',
          component: SmartMenu,
        },
        {
          path: ':shopObjectId/getshop',
          name: 'ShopDetail',
          component: ShopDetail,
        },
        {
          path: ':categoryObjectId/category',
          name: 'MenuByCategory',
          component: MenuByCategory,
        },
      ],
    },
  ]
}

import jwt from 'jsonwebtoken'

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    if (to.query.token === undefined) {
      //เข้าระบบปกติ
      if (!localStorage.getItem('token')) {
        next('/login')
      }
      next()
    } else {
      if (to.query.token !== undefined) {
        const accessToken = to.query.token
        let uid = ''
        let uiddecode = jwt.decode(accessToken)

        if (uiddecode) {
          uid = uiddecode.uid
        }

        localStorage.setItem('token', accessToken)
        localStorage.setItem('uid', uid)
        window.location.href = '/'
      } else {
        window.location.href = '/login'
      }
    }
  }
  next()
})

export default router
