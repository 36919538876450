import liff from '@line/liff'
import lineutil from '@/util/line'

const state = {
  lineProfile: [],
}

const mutations = {
  GET_LINE_PROFILE(statement, lineProfile) {
    statement.lineProfile = lineProfile
  },
}

const actions = {
  async getLineProfile({ commit }, payload) {
    /* liff.init({ liffId: payload }).then(() => {
      let pf = {
        userId: 'Ubee566be4a80aca051e6a1b95e1228f8',
        displayName: 'may .',
        pictureUrl: 'https://cdn-icons-png.flaticon.com/512/921/921124.png',
      }

      commit('GET_LINE_PROFILE', pf)
    }) */

    try {
      await liff.init({ liffId: payload }).then(() => {
        if (liff.isInClient()) {
          if (!liff.isLoggedIn()) {
            liff.login()
          }
          liff
            .getProfile()
            .then((profile) => {
              commit('GET_LINE_PROFILE', profile)
            })
            .catch((err) => {
              console.log('error', err)
            })
        }
      })
    } catch (e) {
      console.error(e.message)
    }
  },
}

const getters = {
  lineProfile: (statement) => statement.lineProfile,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
