const axios = require('axios')
import util from '../util/asymmetric'

const url = process.env.VUE_APP_API_URL

const state = {
  shopLineOA: [],
  subsetsShop: `${localStorage.getItem('shopLineObjectId')}`,
}

const mutations = {
  GET_SHOP_LINE_OA(statement, shopLineOA) {
    statement.shopLineOA = shopLineOA
  },
  SET_SHOP_LINE_OA(statement, subset) {
    statement.subsetsShop = subset
  },
}

const actions = {
  getShopLineOA({ commit }) {
    let urlParams = new URLSearchParams(window.location.search)
    let uid = urlParams.get('uid')

    let token = util.asymmetric()

    axios({
      method: 'get',
      url: url + '/api/v1.0/' + uid + '/Shop/uidwithkey',
      headers: {
        token: token,
      },
    })
      .then((res) => {
        const shopLineOA = res.data.data
        const shopObjectId = res.data.data[0].objectId
        localStorage.setItem('shopLineObjectId', shopObjectId)
        if (state.subsetsShop === 'null') {
          state.subsetsShop = shopObjectId
        }
        commit('GET_SHOP_LINE_OA', shopLineOA)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  setShopLineOA(context, subset) {
    context.commit('SET_SHOP_LINE_OA', subset)
  },
}

const getters = {
  shopLineOA: (statement) => statement.shopLineOA,
  shopLineObjectId: (statement) => statement.subsetsShop,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
