import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import CoreuiVue from '@coreui/vue'
import VCalendar from 'v-calendar'
import { iconsSet as icons } from './assets/icons/icons.js'
import VMdDateRangePicker from 'v-md-date-range-picker'
import VueAnalytics from 'vue-analytics'
import 'regenerator-runtime'
import firebase from 'firebase/app'
import './firebaseInit'
import VueHtml2Canvas from 'vue-html2canvas'
import i18n from './plugins/i18n'
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

Vue.config.performance = true
Vue.config.productionTip = false
Vue.use(CoreuiVue)
Vue.use(VCalendar)
Vue.use(VueHtml2Canvas)
Vue.use(VueDatePicker,{lang:'en'})


Vue.use(VMdDateRangePicker)
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_ANALYTICS,
  router,
})

let app = ''

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      icons,
      i18n,
      render: (h) => h(App),
    }).$mount('#app')
  }
})
