import axios from 'axios'

export default {
  async getProfile(accesstoken) {
    try {
      return await axios({
        method: 'get',
        url: 'https://api.line.me/v2/profile',
        headers: { Authorization: `Bearer ${accesstoken}` },
      })
    } catch (e) {
      console.error(e)
    }
  },
}
